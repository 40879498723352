// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import "mdn-polyfills/Node.prototype.firstElementChild";

import parseXML from "xml-parse-from-string";

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown          as fasFaAngleDown          } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft          as fasFaAngleLeft          } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight         as fasFaAngleRight         } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faBars               as fasFaBars               } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faClock              as fasFaClock              } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment            as fasFaComment            } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faEnvelope           as fasFaEnvelope           } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faFolder             as fasFaFolder             } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faPhone              as fasFaPhone              } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faQuestionCircle     as fasFaQuestionCircle     } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faSearch             as fasFaSearch             } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTag                as fasFaTag                } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faTimes              as fasFaTimes              } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUserCircle         as fasFaUserCircle         } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

import { faBellConcierge      as farFaBellConcierge      } from "@fortawesome/pro-regular-svg-icons/faBellConcierge";
import { faCameraRetro        as farFaCameraRetro        } from "@fortawesome/pro-regular-svg-icons/faCameraRetro";
import { faForkKnife          as farFaForkKnife          } from "@fortawesome/pro-regular-svg-icons/faForkKnife";

import { faFacebookF          as fabFaFacebookF          } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram          as fabFaInstagram          } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTiktok             as fabFaTiktok             } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { faTwitter            as fabFaTwitter            } from "@fortawesome/free-brands-svg-icons/faTwitter";

import svgCottonwoodLodge from "../../media/fac-cottonwood-lodge.svg";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaBars, fasFaClock, fasFaComment, fasFaEnvelope, fasFaFolder, fasFaPhone, fasFaQuestionCircle, fasFaSearch, fasFaTag, fasFaTimes, fasFaUserCircle);

/**
 * Add regular icons
 */
library.add(farFaBellConcierge, farFaCameraRetro, farFaForkKnife);

/**
 * Add brand icons
 */
library.add(fabFaFacebookF, fabFaInstagram, fabFaTiktok, fabFaTwitter);

/**
 * Add custom icons
 */
library.add({
    "cottonwood-lodge": {
        iconName: "cottonwood-lodge",
        prefix: "fac",
        icon: [
            svgCottonwoodLodge.attributes.viewBox.split(" ")[2],
            svgCottonwoodLodge.attributes.viewBox.split(" ")[3],
            [],
            "e000",
            parseXML(svgCottonwoodLodge.content).firstElementChild.getAttribute("d"),
        ]
    },
});

/**
 * Watch the DOM to insert icons
 */
dom.watch();
